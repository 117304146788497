import * as React from 'react'

import { Accordion as AccordionItem } from '@thg-commerce/gravity-system'
import { SafeHtml } from '@thg-commerce/gravity-elements'

import { AccordionWidget } from '@thg-commerce/enterprise-network/src/generated/graphql'

import { BaseWidgetProps } from '../types'

export type AccordionItemWidgetRendererProps = BaseWidgetProps &
  Omit<AccordionWidget, 'query'>

export const AccordionItemWidgetRenderer = (
  props: AccordionItemWidgetRendererProps,
) => {
  if (!props.content) {
    return null
  }
  const child = <SafeHtml content={props.content.content[0].content} />
  return (
    <AccordionItem id={props.id} title={props.title || ''} children={child} />
  )
}
