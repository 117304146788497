import * as React from 'react'

import {
  AccordionWidget,
  AccordionWidgetContainer,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import {
  AccordionContainer,
  AccordionContainerProps,
  AccordionItemProps,
  HorizontalAlignment,
} from '@thg-commerce/enterprise-widget-accordion-container'
import { styled, spacing } from '@thg-commerce/enterprise-theme'

import { BaseWidgetProps } from '../types'

interface AccordionWidgetContainerWithChildren
  extends Omit<AccordionWidgetContainer, 'banners'> {
  banners: Omit<AccordionWidget, 'id' | 'query'>[]
}

export type AccordionContainerWidgetRendererProps = BaseWidgetProps &
  Omit<AccordionWidgetContainerWithChildren, 'query'>

const StyledAccordionContainer = styled(AccordionContainer)`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: ${spacing(2)} auto;
  padding: 0 ${spacing(2)};
`

const getHorizontalPosition = (position: string) => {
  switch (position) {
    case 'centre':
    case 'center':
      return 'center'
    default:
      return position
  }
}

export const AccordionContainerWidgetRenderer = (
  props: AccordionContainerWidgetRendererProps,
) => {
  if (props.banners?.length === 0) {
    return null
  }

  const accordionContainerProps: AccordionContainerProps = {
    title: props.title || undefined,
    // subtitle: undefined,  This needs to be mapped when we take in subtitle from TP
    alignment: getHorizontalPosition(
      props.titlePosition || 'left',
    ) as HorizontalAlignment,
    items: props.banners.map((item) => {
      return {
        title: item.title,
        content: item.content && item.content.content[0].content,
        isOpen: false,
      } as AccordionItemProps
    }),
  }

  return <StyledAccordionContainer {...accordionContainerProps} />
}
